<template>
  <div class="addActivity">
<!--    <a-drawer
        title="发布"
        :with-header="false"
        show-close
        size="50%"
        append-to-body
        :visible.sync="drawer"
        direction="rtl"
    >-->
    <div class="add-heda" >
      <div class="add-heda-text">发布活动</div>
    </div>
        <div class="demand_content" style="width: 80%;margin:20px">
          <a-form-model ref="form" :model="form" :rules="rules" label-width="auto"  :label-col="{ span:7 }"  :wrapper-col="{ span: 17}">
            <a-form-model-item label="活动名称：" prop="activityName">
              <a-input v-model="form.activityName"  placeholder="请输入活动名称" max-length="100" show-word-limit/>
            </a-form-model-item>
            <a-form-model-item label="活动类型：" prop="activityTypeId">
              <a-select v-model="form.activityTypeId" placeholder="请选择活动类型" style="width: 100%">
                <a-select-option
                    v-for="item in activityType"
                    :key="item.id"
                    :value="item.id"
                >{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="活动形式：" prop="activityFormId">
              <a-select v-model="form.activityFormId" placeholder="请选择活动形式" style="width: 100%">
                <a-select-option
                    v-for="item in activityForm"
                    :key="item.id"
                    :value="item.id"
                >{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="活动简介：" prop="activityIntroduction">
              <a-input v-model="form.activityIntroduction"  placeholder="请输入活动简介" max-length="252" show-word-limit/>
            </a-form-model-item>
            <a-form-model-item label="活动内容：">
<!--              <div @mouseover="asdasd('activityContext')">-->
              <Tinymce  @asdasd="asdasd('activityContext')"  ref="Tinymce" v-model="form.activityContext" width="100%"></Tinymce>
<!--              </div>-->
            </a-form-model-item>
            <a-form-model-item label="主讲人：">
              <a-input v-model="form.speaker" max-length="32" placeholder="请输入主讲人"/>
            </a-form-model-item>
            <a-form-model-item label="主讲人简介：">
<!--              <div @mouseover="asdasd('speakerIntroduction')">-->
              <Tinymce  @asdasd="asdasd('speakerIntroduction')"  ref="Tinymce" v-model="form.speakerIntroduction" width="100%"></Tinymce>
<!--              </div>-->
            </a-form-model-item>
            <a-form-model-item label="适应对象：" prop="intended">
              <a-input v-model="form.intended" max-length="255" placeholder="请输入适应对象"/>
            </a-form-model-item>
            <a-form-model-item label="人数限额：" prop="maxPeopel">
              <a-input v-model="form.maxPeopel" max-length="11" placeholder="请输入人数限额" type="number" oninput="value=value.replace(/[^\d]/g,'')"/>
            </a-form-model-item>
            <a-form-model-item label="举办时间：" prop="date">
              <a-range-picker
                  v-model="form.date"
                  format="yyyy-MM-DD HH:mm"
                  valueFormat="yyyy-MM-DD HH:mm"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
              >
              </a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="举办地点：" prop="address">
              <a-input v-model="form.address" max-length="255" placeholder="请输入举办地点"/>
            </a-form-model-item>
            <a-form-model-item label="活动费用：" prop="activityCost">
              <a-input v-model="form.activityCost" placeholder="请输入活动费用" max-length="11" type="number"
                        @input.native="changeRate($event,form.activityCost)"
              />
            </a-form-model-item>
            <a-form-model-item label="报名截止时间：" prop="deadlineTime">
              <a-date-picker
                  v-model="form.deadlineTime"
                  format="yyyy-MM-DD HH:mm"
                  valueFormat="yyyy-MM-DD HH:mm"
                  placeholder="选择报名截止时间"
                  :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
              >
              </a-date-picker>
            </a-form-model-item>
            <a-form-model-item label="联系人：" prop="linkman">
              <a-input v-model="form.linkman"  placeholder="请输入联系人" max-length="32" show-word-limit/>
            </a-form-model-item>
            <a-form-model-item label="联系方式：" prop="phone">
              <a-input v-model="form.phone" max-length="11" placeholder="请输入联系方式"/>
            </a-form-model-item>
            <a-form-model-item label="主办机构：" prop="organizer">
              <a-input v-model="form.organizer"   placeholder="请输入主办机构" max-length="100" show-word-limit/>
            </a-form-model-item>
            <a-form-model-item label="承办方：" prop="host">
              <a-input v-model="form.host" max-length="100" placeholder="请输入承办方"/>
            </a-form-model-item>
            <a-form-model-item label="发布单位：" prop="releaseUnit">
              <a-input v-model="form.releaseUnit" max-length="100" placeholder="请输入发布单位"/>
            </a-form-model-item>
            <a-form-model-item label="附件：" prop="attachment">
              <a-upload
                  :multiple="true"
                  :file-list="fileList"
                  :customRequest="customRequest"
                  @change="handleChange"
              >
                <div>
                  <a-button >
                    <a-icon type="upload"/>
                    上传
                  </a-button>
                  <span style="margin-left: 10px;padding-left:3px;background-color: #ff9a02;color: #F9F9F9"><a-icon type="snippets" />文件大小不能超过10M，最多上传3个附件。&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </div>

                <!--                              <a-button style="width: 95px;height: 46px">-->
                <!--                                浏览-->
                <!--                              </a-button>-->
              </a-upload>
            </a-form-model-item>
            <a-form-model-item label="活动封面图：" prop="activityImg">
              <Uploader @setFileList="setFileList" :limit="1" field="activityImg" :img-str="form.activityImg"
              ></Uploader>
            </a-form-model-item>
            <a-form-model-item label="是否发布：" prop="type">
              <a-radio-group v-model="form.type">
                <a-radio  :value="Number(1)">是</a-radio>
                <a-radio  :value="Number(0)">否</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-form-model>
        </div>
        <div style="text-align: center">
          <a-button @click="goDemand()" style="margin-right: 20px">返回服务页面</a-button>
          <a-button @click="onSubmit" v-show="titel==='发布'" type="primary" >发布</a-button>
          <!--          <a-button @click="onSubmitUpd" v-show="titel==='修改'" type="primary">修改</a-button>-->
          <a-modal v-model="visibleA" title="活动发布" @ok="handleOk">
            <p>是否确认发布</p>
            <p></p>
            <p></p>
          </a-modal>
<!--          <a-button @click="onSubmit"  type="primary" style="background-color: #075FEA">发布</a-button>
&lt;!&ndash;          <a-button @click="onSubmitUpd" v-show="titel==='修改'" type="primary" style="background-color: #075FEA">修改</a-button>&ndash;&gt;
          <a-button @click="drawer = false">取消</a-button>-->
        </div>
      </div>
<!--    </a-drawer>-->
</template>

<script>
import Uploader from '../../../components/Uploader'
import Tinymce from '../../../components/Tinymce'
import {ossUpload} from "../../../api/uploader";
import {addActivity} from '../../../api/company/activity'
import {getDictType} from "../../../api/company/dictType";
import moment from 'moment';
export default {
  name: 'addActivity',
  components: {
    Uploader, Tinymce
  },
  data() {
    return {
      visibleA:false,
      fList: [],
      fileList:[],
      drawer: false,
      titel: '发布',
      activityType: [],
      activityForm: [],
      form: {
        id: undefined,
        activityName: undefined,
        activityTypeId: undefined,
        activityFormId: undefined,
        activityIntroduction: undefined,
        activityContext: undefined,
        speaker: undefined,
        speakerIntroduction: undefined,
        intended: undefined,
        maxPeopel: undefined,
        address: undefined,
        activityCost: undefined,
        deadlineTime: undefined,
        linkman: undefined,
        phone: undefined,
        organizer: undefined,
        host: undefined,
        releaseUnit: undefined,
        attachment: undefined,
        type: undefined,
        activityImg: undefined,
        startTime: null,
        endTime: null,
        date: []
      },
      rules: {
        activityName: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        activityTypeId: [{ required: true, message: '请选择活动类型', trigger: 'blur' }],
        activityFormId: [{ required: true, message: '请选择活动形式', trigger: 'blur' }],
        activityIntroduction: [{ required: true, message: '请输入活动简介', trigger: 'blur' }],
        intended: [{ required: true, message: '请输入适应对象', trigger: 'blur' }],
        maxPeopel: [{ required: true, message: '请输入人数限额', trigger: 'blur' }],
        date: [{ required: true, message: '请选择举办时间', trigger: 'blur' }],
        address: [{ required: true, message: '请输入举办地点', trigger: 'blur' }],
        activityCost: [{ required: true, message: '请输入活动费用', trigger: 'blur' }],
        deadlineTime: [{ required: true, message: '请选择报名截止时间', trigger: 'change' }],
        linkman: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入联系电话', trigger: 'blur' },
          { pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号！' }],
        organizer: [{ required: true, message: '请输入主办机构', trigger: 'blur' }],
        releaseUnit: [{ required: true, message: '请输入发布单位', trigger: 'blur' }],
        activityImg: [{ required: true, message: '请上传活动封面图', trigger: 'blur' }],
        type: [{ required: true, message: '请选择是否发布', trigger: 'change' }],
        activityContext: [{ required: true, message: '请输入活动内容', trigger: 'blur' }],
        speakerIntroduction: [{ required: true, message: '请输入主讲人简介', trigger: 'blur' }],
        speaker: [{ required: true, message: '请输入主讲人名称', trigger: 'blur' }]
      }
    }
  },
  methods: {
    asdasd(name){
      if(name==='activityContext'){
        this.$refs.form.validateField( 'activityContext')
      }else {
        this.$refs.form.validateField( 'speakerIntroduction')
      }

    },
    moment,
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    },
    goDemand(){
      this.$router.go(-1);
    },
    customRequest(data) {
      //console.log(data)
      if(this.fList.length>=3){
        this.$message.success("只允许上传三个附件")
        return
      }
      if(data.file.size/1048576 < 10){
        const formData = new FormData();
        formData.append("pic", data.file)
        ossUpload(formData).then(res => {
          this.fList.push(res.data.url)
        })
      }

    },
    handleChange({file,fileList}) {
      //console.log(file)
      if(file.size/1048576 < 10){
      fileList = fileList.slice(0,3);
      fileList = fileList.map(file => {
        file.status = 'done'
        if (file.response) {
          file.url = file.response.url;
        }
          return file;
      });
      this.fileList = fileList
      }else {
        this.$message.success("文件过大")
      }
    },
   /* show() {
      this.drawer = true
      this.titel = '发布'
      this.form = Object.assign({}, Form)
      this.$nextTick(function() {
        this.$refs.form.clearValidate()
      })
    },*/
    setFileList(obj) {
      //console.log(obj)
      //console.log('==========')
      this.form[obj.field] = obj.img_str
      this.$refs.form.validateField( 'activityImg')
    },
    // selFileList(obj) {
    //   //console.log(obj)
    //   //console.log('===================')
    //   this.form[obj.field] = obj.img_str
    // },
    async  handleOk(e) {
      if (this.fList !== null && this.fList.length !== 0) {
        this.form.attachment = this.fList.join(',')
      }
      delete this.form.date
      await  addActivity(this.form).then(res=>{
        console.log(res,e);
        this.$message.success("发布成功")
        this.$router.go(-1);
      })
      this.visibleA = false;
      this.turnOff();
    },
  async  onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.form.startTime = this.form.date[0] ? this.form.date[0] : null
          this.form.endTime = this.form.date[1] ? this.form.date[1] : null
          if (this.form.endTime > this.form.startTime) {
            this.visibleA = true;
          } else {
            this.$message.error('结束时间不能小于开始时间')
          }
        }
      })
    },
    turnOff() {
      this.$refs.form.resetFields()
    },
    changeRate(e, input) {
      
      console.log(input)
      e.target.value = (e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null
      this.input = e.target.value
      this.form.activityCost = this.input
    }
/*    onSubmitUpd() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.form.startTime = this.form.date[0] ? this.form.date[0] : null
          this.form.endTime = this.form.date[1] ? this.form.date[1] : null
          delete this.form.date
          updateActivity(this.form).then(res => {
            this.$message.success('修改成功！')
            this.drawer = false
            this.$emit('refresh')
          })
        }
      })
    },
    showUpd(id) {
      ActivityDetails({ 'id': id }).then(res => {
        //console.log(res)
        this.form = res.data
        this.form.date = [res.data.startTime, res.data.endTime]
        if (res.data.type === 1) {
          this.form.type = '1'
        } else {
          this.form.type = '0'
        }
        this.drawer = true
        this.titel = '修改'
      })
    },
    look(id) {
      ActivityDetails({ 'id': id }).then(res => {
        //console.log(res)
        this.form = res.data
        this.form.date = [res.data.startTime, res.data.endTime]
        if (res.data.type === 1) {
          this.form.type = '1'
        } else {
          this.form.type = '0'
        }
        this.drawer = true
        this.titel = '详情'
      })
    },
    */
  },
  mounted() {
    getDictType(60).then(res=>{
      this.activityType=res.data;
    });
    getDictType(85).then(res=>{
      this.activityForm=res.data;
    });
  }
}
</script>
<style lang="scss" scoped>
.addActivity{
  width: 1100px;
  //height: 1770px;
  background: #FFFFFF;
  border: 2px solid #EFEFEF;
  margin-left: 110px;
  padding-bottom: 20px;
  .demand_content {
    margin-top: 20px;
    // padding: 20px;
    /*  overflow-y: scroll;
      position: relative;*/
    .form-input{
      width: 435px;
    }
    .form-input :hover{
      border-color:#ff9a02;
    }
  }
  .add-heda{
    width: 1098px;
    height: 50px;
    background: #F9F9F9;
    border-bottom: 2px solid #EFEFEF;
    border-right: 2px solid #EFEFEF;
    .add-heda-text{
      width: 87px;
      height: 21px;
      font-size: 21px;
      font-family: Hiragino Sans GB !important;
      font-weight: normal;
      color: #000000;
      line-height: 45px;
      margin-left: 27px;
      font-weight: 600;
    }
  }
}



</style>
<style lang="scss" >
.ant-checkbox-wrapper:first-child{
  padding-left: 10px;
}
.addActivity{
  .ant-input {
    width: 545px;
    height: 46px;
  }
  .ant-select-selection--single {
    height: 44px;
    width: 545px;
    margin-right: 10px;
    padding-top: 6px;
    border: 1px solid #E1E1E1;
  }
  .ant-select:focus {
    .ant-select-selection--single {
      border-color:#ff9a02;
      box-shadow:0px 0px 0px #ff9a02;
    }

  }
  .ant-select:hover{
    .ant-select-selection--single {
      border-color:#ff9a02;
    }
  }
  .ant-calendar-picker:hover{
  .ant-input{
    border-color:#ff9a02;
  }
  }
  .ant-form-item-required{
    color: #666666 !important;
  }
}
</style>
